exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-donate-js": () => import("./../../../src/templates/donate.js" /* webpackChunkName: "component---src-templates-donate-js" */),
  "component---src-templates-get-involved-js": () => import("./../../../src/templates/get-involved.js" /* webpackChunkName: "component---src-templates-get-involved-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-mallard-pointe-project-js": () => import("./../../../src/templates/mallard-pointe-project.js" /* webpackChunkName: "component---src-templates-mallard-pointe-project-js" */),
  "component---src-templates-privacy-pages-js": () => import("./../../../src/templates/privacy-pages.js" /* webpackChunkName: "component---src-templates-privacy-pages-js" */),
  "component---src-templates-single-project-js": () => import("./../../../src/templates/singleProject.js" /* webpackChunkName: "component---src-templates-single-project-js" */),
  "component---src-templates-single-voice-js": () => import("./../../../src/templates/singleVoice.js" /* webpackChunkName: "component---src-templates-single-voice-js" */),
  "component---src-templates-voices-js": () => import("./../../../src/templates/voices.js" /* webpackChunkName: "component---src-templates-voices-js" */)
}

